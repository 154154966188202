export const fitnessFriend = {
  header: "fitness Friend",
  content: [
    {
      img: {
        src: require("@/assets/mobile/fitness friend.png"),
        alt: null,
        caption: null
      }
    }
  ]
};
