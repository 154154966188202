export const medCo = {
  header: "MedCo",
  content: [
    {
      img: {
        src: require("@/assets/mobile/medical research.png"),
        alt: null,
        caption: null
      }
    }
  ]
};
