<template>
	<div class="scrollIndicator">
		<div class="body">
			<slot />
		</div>
		<div v-if="tagOffset.length > 1" class="nav">
			<a
				v-for="indicator in tagOffset"
				:key="indicator.id"
				:href="'#' + indicator.id"
				:class="{ active: indicator.selected }"
			>
				<span :class="[indicator.selected ? 'fas' : 'far', 'fa-circle']" />
				<span v-text="indicator.id" />
			</a>
		</div>
	</div>
</template>
<script>
	import { debounce } from "@/typeScript/debounce";

	export default {
		name: "ScrollIndicator",
		mixins: [debounce],
		data() {
			//used to calculate the height of header/navigation
			const headerOffset = null;
			//saves the sectrion tags used to generate Sub Content navigation links.
			const tag = [];
			//stores the tag (section) ad well as the top and bottom pixel location of the tag as well as if its selected or not.
			const tagOffset = [];
			//stores height opf the current browser window
			const windowHeight = null;
			//stores pixel value above which we select a tag.
			const windowBuffer = null;
			//ratio used to calculate window buffer
			const bufferRatio = 0.24;
			const prevScrollValue = 0;
			const wait = 0.5;
			const articleTag = null;
			return {
				headerOffset,
				tag,
				tagOffset,
				windowHeight,
				windowBuffer,
				bufferRatio,
				prevScrollValue,
				wait,
				articleTag,
			};
		},
		mounted() {
			setTimeout(() => {
				this.articleTag = document.getElementsByTagName("article")[0];
				// this.goBack();
				this.initialize();
				this.headerOffset.scrollTop = 0;
				this.checkScroll();
			}, this.wait * 1000);
		}, //unmounted
		methods: {
			scrollableHeader: function (highlight) {
				if (
					!document
						.getElementsByTagName("header")[0]
						.classList.contains("showNav")
				) {
					if (this.prevScrollValue >= highlight) {
						document.getElementsByTagName("header")[0].classList.remove("mini");
					} else {
						document.getElementsByTagName("header")[0].classList.add("mini");
					}
				} else {
						document.getElementsByTagName("header")[0].classList.remove("mini");
				}
			}, //scrollableHeader

			goBack: function () {
				if (this.articleTag) {
					const header = this.articleTag.getElementsByTagName("header");
					const backButton = document.createElement("span");
					backButton.className += "fas fa-long-arrow-alt-left fa-2x";
					const backText = document.createElement("span");
					const linkText = document.createTextNode("Go Back");
					backText.appendChild(linkText);
					backButton.appendChild(backText);
					// backButton.classlist.add("fas fa-circle");
					// backButton.onclick = this.$router.back();
					// backButton.href = "http://example.com";
					// console.log(this.$router);
					header.appendChild(backButton);
					// console.log(header);
				}
			}, //goBack

			scrollNav: function (highlight) {
				const breadcrumElement = document.getElementsByClassName("breadcrums")[0];
				const projectNavElement = document.getElementsByClassName("projectNav")[0];
				const filterElement = document.getElementsByClassName("vueFilter")[0];
				// console.log(projectNavElement);
				let projectNavOffset = 0
				if(projectNavElement) {
					projectNavOffset= projectNavElement.offsetTop;
				}
				let filterOffset = 0
				if(filterElement) {
					filterOffset= filterElement.offsetTop;
				}
				if (highlight > 0) {
					//breadcrum
					if (breadcrumElement) {
						breadcrumElement.classList.add("scroll");
					}
					//projectNav
					if(projectNavElement && projectNavOffset && highlight>projectNavOffset) {
						projectNavElement.classList.add("scroll")
					}
					//filter
					if(filterElement && filterOffset && highlight>filterOffset) {
						filterElement.classList.add("scroll")
					}
				} else {
					//breadcrum
					if (breadcrumElement) {
						breadcrumElement.classList.remove("scroll");
					}
					//projectNav
					if(projectNavElement && projectNavOffset && highlight <= projectNavOffset) {
						projectNavElement.classList.remove("scroll")
					}
					//filter
					if(filterElement && filterOffset && highlight<filterOffset) {
						filterElement.classList.remove("scroll")
					}
				}
			}, //scrollNav

			checkScroll: function () {
				const highlight = Math.round(this.headerOffset.scrollTop);
				// console.log(highlight);
				this.scrollableHeader(highlight);
				this.scrollNav(highlight);
				for (let i = 0; i < this.tag.length; i++) {
					const tagOffset = this.tagOffset[i];

					//activate tags depending on the window scroll location and tag top/bottom pixel location within the page.
					if (
						highlight + this.windowBuffer > tagOffset.top &&
						highlight < tagOffset.bottom
					) {
						tagOffset.selected = true;
						if (i > 0) {
							this.tag[i - 1].selected = false;
						}
					} else {
						//keep last tag selected when scroll exceeds bottom pixel location
						if (
							i == this.tag.length &&
							this.windowHeight + highlight >=
								this.tagOffset[this.tagOffset.length - 1].bottom + 32
						) {
							tagOffset.selected = true;
							if (i > 1) {
								this.tag[i - 1].selected = false;
							}
						} else {
							tagOffset.selected = false;
						}
					}
				}
				this.prevScrollValue = highlight;
			}, //checkScroll

			initialize: function () {
				this.headerOffset = document.getElementsByClassName("body")[0];
				const offsetHeader = Array.from(
					document.getElementsByClassName("vueHeader")
				)[0];
				this.tag = Array.from(document.getElementsByTagName("section"));
				this.tag = this.tag.filter((tag) => tag.id != "");
				const headerOffset = offsetHeader ? offsetHeader.offsetHeight : 0;
				this.tagOffset = this.tag.map((section) => {
					return {
						top: section.offsetTop - headerOffset,
						bottom: section.offsetTop + section.offsetHeight - headerOffset,
						id: section.getAttribute("id"),
						selected: false,
					};
				});
				if (this.tag.length > 1) {
					this.headerOffset.addEventListener(
						"scroll",
						this.debounce(this.checkScroll),
						{
							capture: false, // top to bottom bubbling/propogation
							once: false, //should work only once
						}
					);
					this.windowHeight = this.headerOffset.offsetHeight;
					this.windowBuffer = this.windowHeight * this.bufferRatio;
					this.checkScroll();
				}
			}, //initialize
		}, //methods
	};
</script>
<style lang="less" scoped>
	@import (reference) "../../../../Less/customMixins.less";
	@import (reference) "../../../../Less/customVariables.less";

	.scrollIndicator {
		& > div {
			display: flex;
			flex-direction: column;
			align-self: center;
			height: fit-content;

			&.nav {
				position: absolute;
				top: calc(50% + (@header/2));
				right: @spaceLg;
				height: fit-content;
				background-color: @cardBackground;
				border-radius: @spaceXl;
				padding: @spaceXs;
				transform: translateY(-50%);
				z-index: @contentZ+20;
				.boxShadow(@baseText);
				& > a {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					text-decoration: none;
					padding: @spaceMd;
					margin: @spaceSm 0;
					height: @spaceXl;
					gap: @spaceLg;
					z-index: @headerZ+10;
					& > span {
						&:last-child {
							display: none;
						}
					}
					&:hover,
					&.active {
						border-radius: @borderRadius;
						//active block text;
						& > span:last-child {
							display: block;
							position: absolute;
							top: -@spaceXl - @spaceLg;
							right: 0;
							color: @accent;
							padding: 0 @spaceMd;
							background-color: @cardBackground;
							border-radius: @borderRadius;
							.boxShadow(@base);
							width: max-content;
						}
					}
					&:hover {
						border: none;
						& > span:last-child {
							top: auto;
							right: @spaceXl + @spaceLg;
						}
					}
				}
				&::before {
					content: "";
				}
			}

			&.body {
				display: flex;
				align-self: center;
				.responsive(@1600width, 6);
			}
		}
		@media screen {
			@media (max-width: @1600width) {
			}

			@media (max-width: @1200width) {
			}

			@media (max-width: @768width) {
			}

			@media (max-width: @480width) {
			}

			@media (max-width: @320width) {
			}
		}
	}
</style>
