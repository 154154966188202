// export const packStation = {
//   header: "Pack Station",
//   content: [
//     {
//       // img: {
//       //   src: require("@/assets/packstation/9.jpg"),
//       //   alt: null,
//       //   caption: null
//       // },
//       text:
//         "Tha pack station is web app that can be used by users to pack and ship fulfillment orders in a warehouse.This projects is a successful implementation of a grid which follows the Golden Ratio.",
//     }, {
//       // img: {
//       //   src: require("@/assets/packstation/loginPage Packaging.jpg"),
//       //   alt: null,
//       //   caption: null
//       // }
//     }, {
//       img: {
//         src: require("@/assets/packstation/1.jpg"),
//         alt: null,
//         caption: 'Landng Page for Pack Station'
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/2.jpg"),
//         alt: null,
//         caption: 'Error printing label'
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/3.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/4.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/5.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/6.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/7.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/8.jpg"),
//         alt: null,
//         caption: null
//       },
//     }, {
//       img: {
//         src: require("@/assets/packstation/9.jpg"),
//         alt: null,
//         caption: null
//       }
//     }
//   ]
// };
export const packStation = {
  header: "Pack Station",
  content: [
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_01.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_02.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_03.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_04.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_05.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_06.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_07.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_08.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_09.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_10.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_11.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_12.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_13.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_14.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_15.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_16.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_17.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_18.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_19.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_20.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_21.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_22.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_23.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_24.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_25.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
    {
      img: [
        {
          src: require("@/assets/packstation/new/Pack Station (WMS Module)_Page_26.jpg"),
          alt: null,
          caption: null
        }
      ],
    },
  ]
};
