export const plantIt = {
  header: "Plant It",
  content: [
    {
      img: {
        src: require("@/assets/mobile/PlantIt board.png"),
        alt: null,
        caption: null
      }
    }
  ]
};
