import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b2066d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "novalidate", "autocomplete"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "formElements" }
const _hoisted_5 = { class: "formButtons" }
const _hoisted_6 = {
  key: 0,
  class: "btn",
  type: "reset",
  value: "Reset"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_button = _resolveComponent("vue-button")!

  return (_openBlock(), _createElementBlock("form", {
    id: _ctx.tag,
    ref: _ctx.tag,
    class: _normalizeClass({ vueForm: true, singleAction: _ctx.singleAction }),
    name: _ctx.tag,
    novalidate: _ctx.validate,
    autocomplete: _ctx.isAutocomplete,
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ctx && _ctx.ctx(...args)))
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 0,
          textContent: _toDisplayString(_ctx.title)
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          textContent: _toDisplayString(_ctx.description)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_vue_button, {
        type: "submit",
        text: _ctx.text,
        tag: "formActionButton",
        category: _ctx.category,
        icon: _ctx.icon,
        isDisabled: !_ctx.validInput,
        ctx: _ctx.ctx
      }, null, 8, ["text", "category", "icon", "isDisabled", "ctx"]),
      (!_ctx.singleAction)
        ? (_openBlock(), _createElementBlock("input", _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ], 42, _hoisted_1))
}