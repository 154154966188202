export const culinarian = {
  header: "Culinarian",
  content: [
    {
      img: {
        src: require("@/assets/mobile/culinarian.png"),
        alt: null,
        caption: null
      }
    }
  ]
};
